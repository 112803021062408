<template>
  <div class="logistics-list">
    <div class="list-container">
      <div class="search-container">
        <div class="sc-item">
          <label class="sci-title">提单号</label>
          <el-input
            placeholder="输入提单号模糊查询"
            v-model="searchData.cnumber"
            @keyup.enter.native="handleSearch"
            clearable
          >
          </el-input>
        </div>
        <div class="sc-item">
          <label class="sci-title">航班时间</label>
          <el-date-picker
            v-model="searchData.flightTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="sc-item">
          <el-button @click="handleSearch" icon="el-icon-search" type="primary"
            >查询</el-button
          >
          <el-button @click="handleReset" icon="el-icon-refresh-left"
            >重置</el-button
          >
          <el-button type="warning" @click="handelExportExcel"
            >导出Excel</el-button
          >
        </div>
      </div>
      <div class="table-container">
        <el-table
          :data="tableData"
          border
          :header-cell-class-name="tableCellClassName"
        >
          <el-table-column
            type="index"
            :index="calcIndex"
            label="序号"
            width="50"
          >
          </el-table-column>
          <el-table-column
            fixed
            prop=""
            show-overflow-tooltip
            label="提单名义人"
            width="120px"
          >
          </el-table-column>
          <el-table-column
            fixed
            prop="bookerName"
            show-overflow-tooltip
            label="订舱人"
          >
          </el-table-column>
          <el-table-column
            fixed
            prop="flighttime"
            label="航班日期"
            width="120px"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.flighttime.slice(0, 10) }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed label="提单号" width="120">
            <template slot-scope="scope">
              <span
                >{{ scope.row.cnumber.slice(0, -4)
                }}<span class="red bold">{{
                  scope.row.cnumber.slice(-4)
                }}</span></span
              >
            </template>
          </el-table-column>
          <el-table-column
            fixed
            prop="estimatethrowweight"
            label="提单重量"
            width="120px"
          >
          </el-table-column>
          <el-table-column
            fixed
            prop="clearingweight"
            label="货代结算重量"
            width="120px"
          >
          </el-table-column>
          <el-table-column fixed label="货代结算差异" width="120px">
            <template slot-scope="scope">
              {{
                (scope.row.estimatethrowweight * 100 -
                  scope.row.clearingweight * 100) /
                100
              }}
            </template>
          </el-table-column>
          <el-table-column
            v-for="item in agencys"
            :key="item.id"
            :label="item.agency_name"
            width="140px"
          >
            <template slot-scope="scope">
              <template v-for="val in scope.row.info">
                <span v-if="val.agencycode == item.agency_code" :key="val.id">{{
                  val.bulkweight > val.realweight
                    ? val.bulkweight
                    : val.realweight
                }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            prop="agencyWeightTotal"
            label="重量合计"
            width="120px"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            prop="differenceWeight"
            label="重量差异"
            width="120px"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="pageLayout"
        :total="tableTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { PAGESIZELIST, PAGE_LAYOUT, PAGESIZE } from 'config/index'
import Customsbill from 'api/customsbill'
import commonApi from 'api/common'
import { GetToken } from 'utils/auth'
import tableIndex from '@/mixins/table-index'
export default {
  components: {},
  name: '',
  mixins: [tableIndex],
  data() {
    return {
      currentPage: 1,
      pageSize: PAGESIZE,
      pageLayout: PAGE_LAYOUT,
      pageSizes: PAGESIZELIST,
      showCustomsBill: false, // 新增编辑提单
      showEidtAttr: false, // 编辑附件
      searchData: {
        cnumber: '', // 提单号
        flightTime: '' // 航班时间
      },
      detailModel: {},
      tableData: [],
      tableTotal: 0,
      editAttrModel: {
        filetype: '',
        cnumber: '',
        code: '',
        showlicence: '',
        files: '',
        reportcode: '',
        reportprice: '',
        estimateweight: '',
        estimatethrowweight: '',
        estimatebox: ''
      },
      multipleSelection: [],
      agencys: [] // 代理商
    }
  },
  computed: {},
  watch: {},
  created() {
    this.pageSize = 50
  },
  mounted() {
    this.getAgencys()
    this.getLimits()
  },
  destroyed() {},
  methods: {
    tableCellClassName(row, column, rowIndex, columnIndex) {
      console.log(row, column, rowIndex, columnIndex)
      if (row.columnIndex <= 7) {
        return 'table-header'
      } else {
        if (row.row[row.columnIndex].label == '重量差异') {
          return 'table-header-differenced'
        } else {
          return 'table-header-agency'
        }
      }
    },
    // 导出excel
    handelExportExcel() {
      let _start = '',
        _end = ''
      if (this.searchData.flightTime) {
        _start = this.searchData.flightTime[0]
        _end = this.searchData.flightTime[1]
      }
      Customsbill.downLoadCustomsbillReportsExcel({
        token: GetToken(),
        cnumber: this.searchData.cnumber,
        start: _start,
        end: _end
      })
    },
    // 查询
    handleSearch() {
      this.currentPage = 1
      this.tableData = []
      this.getLimits()
    },
    // 重置
    handleReset() {
      this.currentPage = 1
      this.searchData = {
        cnumber: '', // 提单号
        flightTime: '' // 航班时间
      }
      this.tableData = []
      this.getLimits()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.currentPage = 1
      this.pageSize = val
      this.tableData = []
      this.getLimits()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.tableData = []
      this.getLimits()
    },
    // 获取代理商
    getAgencys() {
      commonApi.getAgencys({}, false).then((res) => {
        if (res.success) {
          this.agencys = res.record
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getLimits() {
      let _start = '',
        _end = ''
      if (this.searchData.flightTime) {
        _start = this.searchData.flightTime[0]
        _end = this.searchData.flightTime[1]
      }
      Customsbill.getCustomsBillListReports({
        token: GetToken(),
        cnumber: this.searchData.cnumber,
        start: _start,
        end: _end,
        curr: this.currentPage,
        pagesize: this.pageSize
      }).then((res) => {
        this.tableData = res.record
        this.tableTotal = +res.message
        if (res.success) {
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
::v-deep {
  .table-header-agency {
    background-color: peru !important;
    color: white !important;
    width: 140px !important;
    overflow: hidden !important;
    .cell {
      overflow: hidden;
      text-wrap: nowrap;
    }
  }
  .table-header-differenced {
    background-color: rgb(156, 6, 6) !important;
    color: white !important;
  }
}
.table-children-title,
.table-children-body {
  display: flex;
  height: 44px;
  background: #f2e8ff;
  align-items: center;
  overflow: auto;
  position: relative;
  .agency,
  .useqty,
  .usestate,
  .state,
  .boxqty,
  .weight,
  .realweight,
  .bulkweight,
  .remark,
  .showlicence,
  .reportcode,
  .reportprice,
  .poll,
  .licenceattr {
    width: 200px;
    padding: 0 10px;
    border-right: 1px solid #ddd;
    height: 44px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
  .agency {
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    line-height: 44px;
    width: 219px;
  }
  .useqty,
  .usestate,
  .state,
  .boxqty,
  .realweight,
  .bulkweight,
  .poll,
  .reportcode,
  .reportprice,
  .showlicence {
    width: 160px;
  }

  .weight {
    width: 140px;
  }
  .remark {
    width: 240px;
  }
  .boxqty {
    margin-right: 130px;
    border-right: 0;
  }
  .licenceattr {
    width: auto;
    min-width: 300px;
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  .operation {
    border-left: 1px solid #ddd;
    padding: 0 10px;
    width: 130px;
    position: absolute;
    height: 44px;
    display: flex;
    align-items: center;
    right: 0;
    color: #68488f;
    font-weight: bold;
    font-size: 12px;
    .edit {
      margin-right: 10px;
      cursor: pointer;
    }
    .del {
      cursor: pointer;
    }
  }
}
.table-children-body {
  background: #f9f9f9;
}
.table-childred-body {
  display: flex;
}
.pdf-items {
  display: flex;
  flex-wrap: wrap;
  .attr-info {
    margin-right: 10px;
  }
}
.attr-info {
  .icon-PDF {
    font-size: 13px;
    color: #666;
  }
  a {
    text-decoration: none;
  }
}
.icon-shangchuan {
  color: #68488f;
  cursor: pointer;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    font-size: 13px;
  }
}
::v-deep {
  .el-table__cell.el-table__expanded-cell {
    padding: 0;
  }
  .el-tabs {
    background: #fff;
  }
}
</style>
